.home {
  background-color: $white;
  color: $dark;

  a {
    color: $dark;
  }
}

.cv {
  background-color: $black;
  color: $light;
}

.photography {
  background-color: rgb(19, 19, 19);
  color: rgb(176, 231, 204);
}

// CASE STUDY

.burnt {
  background-color: hsl(0, 0%, 4%);
  color: $light;
}

.desgin {
  background-color: $dark;
  color: $light;
}

.houseof207 {
  background-color: black;
  color: #1500fe;
}

.bbybrandie {
  background-color: $white;
  color: #001f5f;
}

.usoa {
  background-color: $white;
  color: #000000;
}

// PROJECTS
.unrepeatable {
  background-color: #d1c7e2;
  color: #120023;
}

.russiancircles {
  background-color: #000000;
  color: #ffffff;
}

.typecode {
  background-color: #0f1012;
  color: #ffffff;
}

.hcbc {
  background-color: #000000;
  color: #86cec3;
}

.wots {
  background-color: #ece5d1;
  color: #00472c;
}

.galexy {
  background-color: #031657;
  color: #c59c56;
}

.threesomm {
  background-color: white;
  color: #f1a9a8;
}

.mold {
  background-color: white;
  color: #000000;
}

.magazine {
  background-color: #f7f0ee;
  color: #9994b8;
}

.dumbo {
  background-color: #cd7635;
  color: #fff;

  transition: all $animation-long;
}

.c1824 {
  background-color: #fffaf1;
  color: #0c324c;
}

.catvilla {
  background-color: white;
  color: #473a28;
}

.tidalforce {
  background-color: #000000;
  color: #86cec3;

  svg {
    fill: white;
  }
}

.logos {
  background-color: black;
  color: #76d0d3;
}

.volvo {
  background-color: #ffffff;
  color: #ee8801;
}

.youngguns {
  background-color: #00119d;
  color: #00f07b;
}

.ambigram {
  background-color: #027f15;
  color: #f0e000;
}

.chocolate {
  background-color: #fe0200;
  color: #6a0002;
}

.drinks {
  background-color: #000000;
  color: #10e9b8;
}

.sqwishful {
  background-color: #ffffff;
  color: #2a357d;
}

.unionmain {
  background-color: #ffffff;
  color: #001f5f;
}

.genesisnoir {
  background-color: #0f0f0f;
  color: #fdbe00;
}

.fiveandadime {
  background-color: #3b3437;
  color: #b89a76;
}

.palmhouse {
  background-color: #e6e6e6;
  color: #3d3d3d;
}

.sotg {
  background-color: #000000;
  color: #747474;
}

.skateboards {
  background-color: #eeeded;
  color: #46158f;
}

.thecrop {
  background-color: #dcdcdc;
  color: #363636;
}

.guthook {
  background-color: #000000;
  color: #c00000;
}

// TOYS
.selfportrait-23 {
  background-color: white;
  color: #bf5e19;
}

.tonguedemon {
  background-color: black;
  color: #d3c3a8;
}

.nuclearreindeer {
  background-color: black;
  color: #b73a64;
}

.sharky {
  background-color: black;
  color: #00608c;
}

.cookiemonster {
  background-color: black;
  color: #9a6b44;
}

.lube {
  background-color: #03ffdf;
  color: #6d1c07;
}

.skullcat {
  background-color: white;
  color: #c2ae91;
}

.demonbuddy {
  background-color: black;
  color: #d6195d;
}

.catstretch {
  background-color: #140f0d;
  color: #867f7a;
}

.desginyeti {
  background-color: #d3dddf;
  color: #7193ab;
}

.fryboi {
  background-color: #e9e9e9;
  color: #801def;
}

.maruska-normal {
  background-color: #080202;
  color: #ad0e03;
}

.maruska-blackmetal {
  background-color: #000000;
  color: #b15500;
}

.drunkbee {
  background-color: #000000;
  color: #ac7a1c;
}

.tepache {
  background-color: #ceb583;
  color: #f7eede;
}

.tooth {
  background-color: #d0cdbf;
  color: #a86761;
}

.surreal {
  background-color: #000000;
  color: #8f8f8f;
}

.dungeonrat {
  background-color: #000000;
  color: #a50e0e;
}

// EXPERIMENTS

.intercourse {
  background: rgb(0, 10, 140);
  background: linear-gradient(180deg, rgba(0, 10, 140, 1) 0%, rgba(255, 26, 26, 1) 100%);
  background-attachment: fixed;

  color: white;

  svg {
    fill: white;
  }
}

.outercourse {
  background: rgb(20, 203, 135);
  background: linear-gradient(180deg, rgba(20, 203, 135, 1) 0%, rgba(11, 20, 208, 1) 100%);
  background-attachment: fixed;

  color: white;

  svg {
    fill: white;
  }
}

.space2 {
  background: rgb(214, 5, 127);
  background: linear-gradient(180deg, rgba(214, 5, 127, 1) 0%, rgba(47, 0, 161, 1) 100%);
  background-attachment: fixed;

  color: white;

  svg {
    fill: white;
  }
}

.spacetron {
  background-color: #000000;
  color: #36b3aa;
}

.infrared {
  background-color: #ffffff;
  color: #ce0a0a;
}

// FIXERS

body {
  a {
    color: currentColor;
  }
}
