nav{
    width: 100%;
    max-width: $site-width;

    display: flex;
    position: fixed;
    margin: 0 $spacing;
    top: $spacing;

    z-index: 10;

    div{
        width: 25%;

        a{
            display: block;
        }
    }

    @media screen and (max-width: $break-small){

        div{
            &:nth-child(2){
                width: 0%;
            }

            &:nth-child(4){
                width: 40%;
            }
        }
    }
}

.offmain{
    nav{
        div{
            width: 25%;
    
            a{
                display: block;
                margin-right: 5px;
            }
        }

        @media screen and (max-width: $break-small){
            div{
                &:nth-child(1){
                    width: 21%;
                }

                &:nth-child(2){
                    width: 21%;
                }

                &:nth-child(3){
                    width: 21%;
                }
    
                &:nth-child(4){
                    width: 37%;
                }
            }
        }
    }
}