.offmain body{
  transition: $animation-long;
}


/***********************
 * Norway
 ***********************/
.norway{
  background-color: #e6e8ef;
  color: #8c9ebb;
}

body.norway-reindeer { 
  background: #151515; 
  color: rgb(248, 244, 233);
}

body.norway-bergen { 
  background: #e3e2e6; 
  color: #8c9ebb;
}

body.norway-fantoft { 
  background: #bebec0; 
  color: #343438;
}

body.norway-fjords { 
  background: #a7acb0; 
}

body.norway-town { 
  background: #0e0f15; 
  color: #52525a;
}

body.norway-flam { 
  background: #a0aab1; 
  color: white;
}

body.norway-lookout { 
  background: #0e0f15; 
}

body.norway-oslo { 
  background: #a0aab1; 
}



/***********************
 * Starkfest
 ***********************/
 .starkfest{
  background-color: #e1d3b7;
  color: #912c1a;
}

body.stark-friends { 
  background: #2c070e; 
  color: rgb(248, 244, 233);
}

body.stark-sky { 
  background: #a9c9e1; 
  color: #ec372d;
}

body.stark-nighttime { 
  background: #000000; 
  color: rgb(252, 112, 31);
}

body.stark-timewarp { 
  background: #000000; 
  color: #89a54d;
}

body.stark-volvo { 
  background: #69c5ba; 
  color: #2c2c2c;
}

body.stark-family { 
  background: #929eaa; 
}


/***********************
 * Cochranton
 ***********************/
 .cochranton{
  background-color: #101017;
  color: #bd7a29;
}


/***********************
 * Hamptons
 ***********************/
 .hamptons{
  background-color: white;
  color: black;
}

body.hamptons-dark { 
  background: #171717; 
  color: #888888;
}

body.hamptons-light { 
  background: #efefef; 
  color: #333333;
}

body.hamptons-mid { 
  background: #c1c1c1; 
  color: #333333;
}

body.hamptons-middark { 
  background: #8d8d8d; 
  color: #333333;
}

body.hamptons-color { 
  background: #f8edd4; 
  color: #6c5d64;
}


/***********************
 * Copenhagen
 ***********************/
.copenhagen{
  background-color: rgb(15, 15, 15);
  color: rgb(119, 119, 119);
}

body.copenhagen-dark { 
  background: rgb(35, 35, 35); 
}

body.copenhagen-mid { 
  background: rgb(55, 55, 55); 
}


/***********************
 * Japan
 ***********************/
 .japan{
  background-color: rgb(197, 190, 176);
  color: rgb(255, 255, 255);
}

body.japan-north { 
  background: #cde3de; 
}

body.japan-kanamara { 
  background: #e6dddd; 
}

body.japan-cherry { 
  background: #cfd7d7; 
}

body.japan-fuji { 
  background: #e1dae8; 
}

body.japan-tokyo2 { 
  background: #cfd7d7; 
}



/***********************
 * Iceland
 ***********************/
.iceland{
  background-color: #dae0e8;
  color: rgb(137, 150, 179);
}

body.iceland-north { 
  background: #d2dfe4; 
  color: #33407c;
}

body.iceland-east { 
  background: #cfc3ab; 
  color: #5e4028;
}

body.iceland-south { 
  background: #151515; 
  color: #b2c1d8;
}



/***********************
 * St John
 ***********************/
 .stjohn{
  background-color: #cbe9f0;
  color: #0ab6d4;
}


/***********************
 * Port Townsend
 ***********************/
.port{
  background-color: #dcecde;
  color: #2a4a12;
}

body.port-travel { 
  background: #e7e7e7; 
  color: #363e63;
}

body.port-home { 
  background: #ece9dc; 
  color: #41371a;
}

body.port-whale { 
  background: #d2e0e5; 
  color: #25283a;
}



/***********************
 * MISC
 ***********************/
.misc{
  background-color: #fff;
  color: #6792eb;
}

body.misc-la { 
  background: #b9d5e7; 
  color: #c72424;
}

body.misc-ct { 
  background: #fadada; 
  color: #5a94bd;
}

body.misc-simms { 
  background: #fbebd7; 
  color: #242632;
}


/***********************
 * Caicos
 ***********************/
.caicos{
  background-color: #d4f3fe;
  color: #0f4558;
}

body.iguana { 
  background: hsl(2, 72%, 4%); 
  color: #c42f29;
}

body.buggy-town { 
  background: #d8eef6; 
  color: #07d44b;
}

body.dinner-bus { 
  background: #cce8ed; 
  color: #3686ac;
}

body.leaving-resort { 
  background: #1f0f10; 
  color: #f97218;
}


/***********************
 * RETREAT
 ***********************/
.retreat-horse{
  .non-horse{
    display: block;
  }
  .horse{
    display: none;
  }
  &:hover{
    .non-horse{
      display: none;
    }
    .horse{
      display: block;
    }
  }

}



.retreat{
  background-color: #b5c2ee;
  color: #fdf2e3;
}

body.retreat-2 { 
  background: #f8f8f8; 
  color: #e43c1a;
}

body.retreat-3 { 
  background: #5b8187; 
  color: #d2948e;
}

body.retreat-4 { 
  background: #120709; 
  color: #87c1e9;
}

body.retreat-5 { 
  background: #000000; 
  color: #b57034;
}



/***********************
 * Scotland
 ***********************/
 .scotland{
  background: #cccccc; 
  color: #7e2219;
}

body.scotland-2 { 
  background-color: #d4f3fe;
  color: #5e8ea3;
}

body.scotland-3 { 
  background: #1f1c2c; 
  color: #9e9dad;
}

body.scotland-4 { 
  background: #dbe2e2; 
  color: #f19d51;
}

body.scotland-5 { 
  background: #2c333e; 
  color: #9e8c7c;
}