.home .nav-col-1,
.home .nav-col-1 a {
  visibility: hidden;
  opacity: 0;
}

.home nav div a {
  transition: none;
}

.home-intro {
  margin: clamp(10vw, 200px, 15vw) 0;
  opacity: 1;

  @media screen and (max-width: $break-small) {
    margin: clamp(10vw, 150px, 30vw) 0 50px;
  }

  .item {
    opacity: 1;
  }
}

.home-casestudy {
  display: flex;
  margin: calc($spacing * 8) 0;
  padding: 10px 0 0 0;

  z-index: 1;

  transition: opacity $animation-short;

  div {
    &:first-child {
      width: 25%;
      position: sticky;
      top: 5vw;
    }

    &:nth-child(2) {
      width: 85%;

      img {
        width: 100%;
      }
    }
  }

  .home-casestudy-col-1 {
    p {
      &:last-child {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.header-home {
  margin: calc($spacing * 8) 0 50px 0;
}

.home-projects-wrap {
  margin: calc($spacing * 4) 0;
  position: relative;

  @media screen and (max-width: $break-small) {
    margin: 75px 0;
  }
}

$homelines: rgb(150 150 150 / 0%);
$homelineshover: rgba(80 80 80 / 0.5);

.home-projects-title,
.home-projects-type,
.home-projects-year {
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media screen and (max-width: $break-small) {
    display: block;
    white-space: normal;
  }

  &::after {
    content: '';
    width: 100%;
    background: $homelines;
    height: 1px;
    margin: 0px 20px;
  }
}

.list-wrap {
  opacity: 1;
  transition: opacity $animation-med;

  &:hover {
    .home-projects-casestudy,
    .home-projects-projects,
    .home-projects-experiments,
    .home-projects-photography,
    .home-projects-toys {
      opacity: 0.2;
    }
  }

  .home-projects-casestudy,
  .home-projects-projects,
  .home-projects-experiments,
  .home-projects-photography,
  .home-projects-toys {
    transition: opacity $animation-med;

    &:hover {
      opacity: 1;
    }
  }
}

.home-projects-title,
.home-projects-type,
.home-projects-year {
  transition: opacity $animation-med;
}

.home-headline {
  margin: 0;
  padding: 10px 0;
  font-weight: $font-bold;
}

.home-projects {
  display: flex;
  position: relative;
  margin: 0;
  padding: 10px 0;

  z-index: 10;

  transition: all $animation-med;

  @media screen and (max-width: $break-small) {
    padding: 8px 0;
  }

  &:hover {
    padding: 60px 0;

    .home-projects-title,
    .home-projects-type,
    .home-projects-year {
      opacity: 1;

      &::after {
        background: $homelineshover;
      }
    }
  }

  div {
    &:nth-child(1) {
      width: 50%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 25%;
    }

    @media screen and (max-width: $break-small) {
      &:nth-child(1) {
        width: 38%;
        margin-right: 5%;
      }

      &:nth-child(2) {
        width: 30%;
        margin-right: 2%;
      }

      &:nth-child(3) {
        width: 22%;
      }
    }
  }
}

.home-projects-header {
  display: flex;
  margin: 0;
  padding: 10px 0;

  color: $light;

  div {
    &:first-child {
      width: 50%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 25%;
    }
  }
}

.image-reveal {
  position: fixed;
  max-width: 400px;
  max-height: 400px;
  width: 80vw;
  height: auto;
  object-fit: contain;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  z-index: -1;
  pointer-events: none;

  opacity: 0;
  transition: opacity 20ms ease;

  &:hover {
    transition: opacity 400ms ease;
  }
}

.maruska-hover {
  display: inline;
  transition: none;
}

.home-image-container {
  position: absolute;
  right: $spacing;
  z-index: -1;
  width: 25vw;
  height: 25vw;
  margin-top: -12.5vw;

  opacity: 0;

  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  pointer-events: none;

  // transition: all $animation-short;
}

.home-projects {
  &:hover {
    .home-image-container {
      opacity: 1;

      @media screen and (max-width: $break-small) {
        opacity: 0;
      }
    }
  }
}

.disabled {
  opacity: 0.25;
}

#words .item {
  animation: words 700ms ease forwards 1;
  opacity: 0.15;
}

@for $i from 1 through 39 {
  #words .item:nth-child(#{$i}) {
    animation-delay: $i * 0.1s;
  }
}

@keyframes words {
  from {
    opacity: 0.15;
  }

  to {
    opacity: 1;
  }
}
