body{
    background-color: $white;

    transition: all $animation-med ease;
}

main{
    max-width: $site-width;
    margin: 0 auto;
}


.cv-item{
    display: flex;
    margin: 0 0 calc($spacing * 6);

    .cv-title, .cv-time{
        width: 25%;
    }

    .cv-job{
        width: 50%;
    }

    @media screen and (max-width: $break-small){
        display: block;
        margin: 0 0 calc($spacing * 12);

        .cv-title, .cv-time, .cv-job{
            width: 100%;
            margin: 0 0 calc($spacing * 2);
        }
    }
}