header {
  margin: clamp(10vw, 200px, 15vw) 0 100px;
  display: flex;

  @media screen and (max-width: $break-small) {
    display: block;
    margin: clamp(30vw, 150px, 50vw) 0 50px;
  }

  div {
    @media screen and (max-width: $break-small) {
      margin: calc($spacing * 4) 0;
      width: 100%;
    }

    &:nth-child(1) {
      width: 23%;

      @media screen and (max-width: $break-small) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 52%;

      @media screen and (max-width: $break-small) {
        width: 90%;
      }

      p {
        margin: 0 25px 0 0;
        max-width: 600px;
        text-indent: calc($spacing * 2);
      }
    }

    &:nth-child(3) {
      width: 25%;

      @media screen and (max-width: $break-small) {
        width: 75%;
      }
    }
  }
}

section {
  margin: 0;

  &:last-child {
    margin-bottom: 4vw;
  }

  img,
  video {
    width: 100%;
    outline: none;
  }

  .standard,
  .flex {
    margin: calc($spacing / 1.25) 0;
  }

  .full {
    margin: 0 (-$spacing);
    width: 100vw;
  }

  .small {
    max-width: calc(100% - ($spacing * 20));
    width: 100%;
    margin: calc($spacing * 10) auto;

    @media screen and (max-width: $break-small) {
      width: 100%;
      max-width: 100%;
      margin: calc($spacing / 2) auto;
    }
  }

  .small-image {
    width: 70%;
    margin: 0 auto;
    display: block;
  }

  .extra-small {
    max-width: calc(100% - ($spacing * 50));
    width: 100%;
    margin: calc($spacing * 10) auto;

    position: relative;

    @media screen and (max-width: $break-small) {
      width: 100%;
      max-width: 100%;
      margin: calc($spacing / 2) auto;
    }
  }

  .large-margin {
    margin: 15vw auto;
  }

  .large-bottom {
    margin-bottom: 10vw;
  }

  .flex {
    display: flex;
    align-items: center;
    position: relative;
    gap: $spacing;

    div {
      width: 100%;
    }

    .half-right-p {
      width: 25%;
      position: absolute;
      right: 0;
      top: 0;
      text-indent: calc($spacing * 2);

      @media screen and (max-width: $break-small) {
        width: 48%;
      }
    }

    // .half-left-img {
    //   max-width: 50%;
    // }

    .half-left-p {
      width: 25%;
      position: absolute;
      left: 0;
      bottom: 0;
      text-indent: calc($spacing * 2);
    }

    // .half-right-img {
    //   margin-left: 50%;
    //   max-width: 100%;
    // }

    // div {
    //   margin: 0 calc($spacing / 3);
    //   width: 50%;

    //   @media screen and (max-width: $break-small) {
    //     margin: 0 calc($spacing / 2);
    //   }

    //   &:first-child {
    //     margin-left: 0;
    //     z-index: 2;
    //   }

    //   &:last-child {
    //     margin-right: 0;
    //   }
    // }
  }

  .mobile-break {
    @media screen and (max-width: $break-small) {
      display: block;

      div {
        width: 100%;

        &:first-child,
        &:last-child {
          margin: 0;
          max-width: 100%;
        }
      }
    }
  }
}

.photo-intro {
  margin: clamp(10vw, 200px, 15vw) 0;

  @media screen and (max-width: $break-small) {
    margin: clamp(10vw, 150px, 30vw) 0 50px;
  }

  .photo-bio {
    display: flex;

    @media screen and (max-width: $break-small) {
      display: block;
    }

    div {
      @media screen and (max-width: $break-small) {
        margin: calc($spacing * 4) 0;
        width: 100%;
      }

      &:nth-child(1) {
        width: 23%;

        @media screen and (max-width: $break-small) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        width: 52%;

        @media screen and (max-width: $break-small) {
          width: 90%;
        }

        p {
          margin: 0 25px 0 0;
          max-width: 600px;
          text-indent: calc($spacing * 2);
        }
      }

      &:nth-child(3) {
        width: 25%;

        @media screen and (max-width: $break-small) {
          width: 75%;
        }
      }
    }
  }
}

.project-gallery {
  outline: none;
  cursor: grab;
  overflow: hidden;
  margin: calc($spacing) (-$spacing);
  z-index: 1;

  .slide {
    margin: 0 calc($spacing / 3);

    img {
      display: block;
      max-width: 40vw;
      height: auto;
      width: 100%;

      @media screen and (max-width: $break-small) {
        max-height: 350px;
      }
    }
  }
}

.small-slide {
  .slide {
    img {
      // max-height: 500px;
      max-width: 30vw;
    }
  }
}

.lottie-large {
  max-width: 100%;
  width: 100%;
  margin: auto;
}

.lottie-small {
  max-width: 1120px;
  width: 100%;
  margin: auto;
}

.lottie-xs {
  max-width: 800px;
  width: 100%;
  margin: auto;
}

// Scoll JS Styles
.scrollable-container {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: $break-small) {
    display: block;
    height: auto !important;
  }
}

.scrollable-section {
  width: 50%;
  overflow: hidden;
  position: sticky;
  top: 0;

  @media screen and (max-width: $break-small) {
    width: 100%;
    overflow: auto;
    position: relative;
  }
}

.scrollable-section-inner {
  height: 100vh;
  @media screen and (max-width: $break-small) {
    height: auto;
  }
}

.scrollable-section-inner {
  img {
    max-width: calc(100% - $spacing);
    margin: $spacing;
    height: auto;
  }
}

.rounded {
  border-radius: 5px;

  @media screen and (max-width: $break-small) {
    border-radius: 5px;
  }
}

.border {
  width: calc(100% - 10px);
  border: 5px solid $black;

  @media screen and (max-width: $break-small) {
    border: 3px solid $black;
    width: calc(100% - 6px);
  }
}

.border-lite {
  width: calc(100% - 10px);
  border: 5px solid $black;

  @media screen and (max-width: $break-small) {
    border: 3px solid $black;
    width: calc(100% - 6px);
  }
}

.border-video {
  border-top: 3px solid $black;
  border-left: 3px solid $black;
  border-right: 3px solid $black;

  border-bottom: 5px solid $black;

  width: calc(100% - 6px);

  @media screen and (max-width: $break-small) {
    border: 3px solid $black;
  }
}

.shadow {
  img {
    max-width: calc(100% - ($spacing * 4));
    margin: calc($spacing * 2);
    box-shadow: 2px 4px 30px rgb(0 0 0 / 20%);
  }
}

.rotate {
  animation: spin 35s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

model-viewer {
  width: 100%;
  height: 80vh;
  background: black;

  @media screen and (max-width: $break-small) {
    height: 50vh;
  }
}
